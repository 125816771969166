<template>
  <div class="protokoll-datum">
    <strong>Datum*:</strong>
    <CRow class="form-group">
      <CCol sm="12">
    <strong title="nicht mehr änderbar.">{{ datum.toLocaleDateString() }} (heute)</strong>
      </CCol>
      </CROW>
  </div>
</template>

<script>

export default {
  name: 'ProtokollStatus',
  computed: {
  },
  mounted () {
    this.datum = new Date()
  },
  data () {
    return {
      datum: new Date()
    }
  },
  watch: {
    datum: function () {
      this.$emit('input', this.datum)
    }
  },
  methods: {
  }
}
</script>
