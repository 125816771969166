<template>
  <div class="material repeating-fields mt-3">
    <CRow>
      <CCol><h5 class="font-weight-bold" style="font-size: 1rem;">Arbeitszeit</h5></CCol>
    </CRow>
    <CRow class="labels" style="margin-left: 1rem;">
      <CCol class="d-none d-md-block col-md-2">
        Datum
      </CCol>
      <CCol class="d-none d-md-block col-md-2">
       Dauer
      </CCol>
      <CCol class="d-none d-md-block col-md-4">
        Mitarbeiter
      </CCol>
      <CCol class="d-none d-md-block col-md-4">
        Tätigkeit
      </CCol>
    </CRow>
    <VueRepeaterLocal v-model="arbeitszeiten" identifier="formular-arbeitszeit"/>
    <CRow>
      <CCol class="col-3"><h5 class="font-weight-bold" style="font-size: 1rem;"></h5></CCol>
      <CCol class="col-9 px-3 py-2 right text-right">
        <CButton
          color="success"
          size="sm"
          class="right text-right"
          @click="addEntry()"
          v-c-tooltip="{content: 'Hinzufügen', placement:'left'}"
        >
          Arbeitszeit hinzufügen
          <CIcon name="cil-library-add"/>
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { EventBus } from '@/helpers/eventBus'
import VueRepeaterLocal from '@/components/VueRepeater/VueRepeaterLocal'

export default {
  name: 'ProtokollArbeitszeit',
  components: {
    VueRepeaterLocal
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    zukuenftigBerechnen: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      arbeitszeiten: [
        {
          name: 'formular-arbeitszeit',
          value: {}
        }
      ],
      doEmit: true
    }
  },
  methods: {
    addEntry: function () {
      EventBus.$emit('addRowArbeitszeit', 'formular-arbeitszeit')
    }
  },
  watch: {
    zukuenftigBerechnen: function () {
      const self = this
      this.doEmit = false
      // Einträge löschen, deren Value auf Auto steht
      while (this.arbeitszeiten.length > 0) {
        this.arbeitszeiten.pop()
      }
      this.arbeitszeiten.push({
        name: 'formular-arbeitszeit',
        value: {}
      })
      // Neue Einträge pushen
      if (this.zukuenftigBerechnen) {
        if (this.zukuenftigBerechnen.length > 0) {
          this._.forEach(this.zukuenftigBerechnen, function (z) {
            const tmp = {
              name: 'formular-arbeitszeit',
              value: z
            }
            self.arbeitszeiten.push(tmp)
          })
        }
      }
      this.doEmit = true
      this.arbeitszeiten.slice()
    },
    arbeitszeiten: function () {
      if (this.doEmit) {
        const results = []
        this._.forEach(this.arbeitszeiten, function (zt) {
          if (zt.value) {
            const value = zt.value
            if ('datum' in value && 'dauer' in value && 'mitarbeiter' in value) {
              if (value.datum !== '' && value.dauer > 0 && value.mitarbeiter > 0) {
                results.push(value)
              }
            }
          }
        })
        this.$emit('input', results)
      }
    }
  }
}
</script>
